import Swiper, { Autoplay, Navigation, Pagination, EffectFade} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
jQuery(document).ready(function($) {
	if ( typeof Swiper !== "undefined" ){
		// Image carousel
		new Swiper('.image-carousel', {
			modules: [Autoplay, Pagination, Navigation],
			slidesPerView: "auto",
			loop: true,
			centeredSlides: true,
			spaceBetween: 20,
			autoHeight: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
            pagination: {
                el: '.swiper-pagination',
				// type: "fraction",
                clickable: true,
				type: 'custom',
				renderCustom: function (swiper, current, total) {
					return current + ' of ' + total;
				}
            },
			// breakpoints: {
			// 	968: {
			// 		slidesPerView: "auto",
			// 		spaceBetween: 20,
			// 	},
			// 	1200: {
			// 		slidesPerView: "auto",
			// 		spaceBetween: 20,
			// 	},
			// },
		});

		new Swiper('.page-header-carousel', {
			modules: [Autoplay, Pagination, Navigation, EffectFade],
			slidesPerView: '1',
			loop: false,
			grabCursor: true,
			effect: 'fade',
            crossFade: 'true',
			spaceBetween: 0,
			pagination: {
				el: '.page-header-carousel-pagination',
				clickable: true
			},
		});

		// attractions carousel
		new Swiper('.attractions-carousel', {
			modules: [Autoplay, Pagination, Navigation],
			slidesPerView: '1',
			loop: false,
			grabCursor: true,
			spaceBetween: 0,
			navigation: {
				nextEl: '.attractions-carousel-next',
				prevEl: '.attractions-carousel-prev',
			},
            pagination: {
                el: '.attractions-carousel-pagination',
                // clickable: false,
				clickable: true
            },
			breakpoints: {
				700: {
					slidesPerView: "2"
				},
				1000: {
					slidesPerView: "3"
				},
			},
		});
	}
});
